.tableMaterial {
    width: 100%;
    /* max-width: 1050px; */
    margin: 0 auto;
    text-align: center;
    font-size: 12px;
    th.mat-header-cell {
      text-align: center;
      text-align: left;
      padding: 0 5px;
      color: black;
      border-bottom: 1px solid rgba(0, 0, 0, 0.818);
    }
    .mat-footer-row,
    .mat-header-row,
    .mat-row {
      height: 32px;
    }
    .mat-cell{
      font-size: 12px;
      text-align: left;
      padding: 0 5px;
      border-color: rgb(204, 203, 203);
      p{
        text-align: center ;
      }
    }
    .mat-row:hover .mat-cell {
      background-color: whitesmoke;
    }
    td.mat-cell:last-of-type{
      padding-right: 0;
    }
  
  }
 

@media (max-width: 950px) {

      .tableMaterial {
        max-width: 90%;
        display: block;
        overflow-x: auto;
        white-space: nowrap;
      }
    }