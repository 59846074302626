/* You can add global styles to this file, and also import other style files */

@use "./0-base/base_dir" as *;
@use "./1-components/base_dir" as *;

/* html, body { height: 100%; } */
body {
  margin: 0;
  width: 100%;
  min-height: 100%;
  background-color: #FAFAFA;
}
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Metropolis", sans-serif !important;
}
html {
  overflow-y: auto;
  scrollbar-color: var(--brand-palette-100) #f9fafd;
  scrollbar-width: thin;
}

mat-icon {
  font-family: "Material Icons" !important;
}
/* .mat-tab-labels{
    justify-content: center !important;
} */
.mat-mdc-tab-body-content {
  background-color: var(--white);
}
.mat-mdc-dialog-container {
  --mdc-dialog-container-shape: 16px;
}
